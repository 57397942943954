import React from "react";
import { useStaticQuery, graphql } from 'gatsby'

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import TitleProduct from "../../objects/TitleProduct"
import BannerProduct from "../../components/BannerProduct"
import CardProduct from "../../components/CardProduct"
import CardsSection from "../../components/CardsSection"
import FreeShippingSection from "../../components/FreeShippingSection"
import { cards } from "./content"

const PapeisDeParede = () => {

   const imgContent = useStaticQuery (
      graphql`
         query {
            imgBanner: file(relativePath: { eq: "banner-papel-de-parede.jpg" }) {
               childImageSharp {
                  fluid(maxHeight: 515) {
                     ...GatsbyImageSharpFluid
                  }
               }
            }
            imgCards: allFile(
                  sort: {order: ASC, fields: 
                     childImageSharp___fluid___originalName},
                  filter: {relativeDirectory: {eq: "papeis-de-parede"}}) {
               edges {
                 node {
                   childImageSharp {
                     fluid(maxWidth: 576) {
                        ...GatsbyImageSharpFluid
                     }
                   }
                 }
               }
            }
         }
      `
   )

   const imagesCards = imgContent.imgCards.edges

   return (
      <Layout>
         <SEO title="Papéis de Parede"/>
         <Header title=""/>
         <TitleProduct title="Papéis de Parede"/>
         <BannerProduct 
            imgFluid={imgContent.imgBanner.childImageSharp.fluid}
         />
         <CardsSection>
            {imagesCards.map((img, i) => {
               return (
                  <CardProduct 
                     key={i}
                     target={cards[i].target}
                     imgFluid={img.node.childImageSharp.fluid}
                     alt={cards[i].alt}
                     title={cards[i].title}
                     description={cards[i].description}
                  />
               )
            })}
         </CardsSection>
         <FreeShippingSection />
         <Footer />
      </Layout>
   )
}

export default PapeisDeParede;