const cards = [
   {
      id: "img01",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-3",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-3",
   },
   {
      id: "img02",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-4",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-4",
   },
   {
      id: "img03",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-8",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-8",
   },
   {
      id: "img04",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-10",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-10",
   },
   {
      id: "img05",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-12",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-12",
   },
   {
      id: "img06",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-14",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-14",
   },
   {
      id: "img07",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-18",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-18",
   },
   {
      id: "img08",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-20",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-20",
   },
   {
      id: "img09",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-22",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-22",
   },
   {
      id: "img10",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-24",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-24",
   },
   {
      id: "img11",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-26",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-26",
   },
   {
      id: "img12",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-29",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-29",
   },
   {
      id: "img13",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-30",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-30",
   },
   {
      id: "img14",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-32",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-32",
   },
   {
      id: "img15",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-35",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-35",
   },
   {
      id: "img16",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-37",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-37",
   },
   {
      id: "img17",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-40",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-40",
   },
   {
      id: "img18",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-44",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-44",
   },
   {
      id: "img19",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-46",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-46",
   },
   {
      id: "img20",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-49",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-49",
   },
   {
      id: "img21",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-52",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-52",
   },
   {
      id: "img22",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-54",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-54",
   },
   {
      id: "img23",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-60",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-60",
   },
   {
      id: "img24",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-61",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-61",
   },
   {
      id: "img25",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-63",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-63",
   },
   {
      id: "img26",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-67",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-67",
   },
   {
      id: "img27",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-71",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-71",
   },
   {
      id: "img28",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-73",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-",
   },
   {
      id: "img29",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-75",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-75",
   },
   {
      id: "img30",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-77",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-77",
   },
   {
      id: "img31",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-79",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-79",
   },
   {
      id: "img32",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-81",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-81",
   },
   {
      id: "img33",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-83",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-83",
   },
   {
      id: "img34",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-86",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-86",
   },
   {
      id: "img35",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-88",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-88",
   },
   {
      id: "img36",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-91",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-91",
   },
   {
      id: "img37",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-93",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-93",
   },
   {
      id: "img38",
      target: "",
      alt: "Papel de parede Acacia importados fiance-429-97",
      title: "Papel de Parede Acácia Importados Fiance",
      description: "429-97",
   },
   {
      id: "img39",
      target: "",
      alt: "Papel de parede 00002768-pg--35---ll-00307",
      title: "Papel de parede",
      description: "2768-PG35-307",
   },
   {
      id: "img40",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-2",
      title: "Papel de parede Coreano Acácia",
      description: "32014-2",
   },
   {
      id: "img41",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-4",
      title: "Papel de parede Coreano Acácia",
      description: "32014-4",
   },
   {
      id: "img42",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-31",
      title: "Papel de parede Coreano Acácia",
      description: "32014-31",
   },
   {
      id: "img43",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-33",
      title: "Papel de parede Coreano Acácia",
      description: "32014-33",
   },
   {
      id: "img44",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-63",
      title: "Papel de parede Coreano Acácia",
      description: "32014-63",
   },
   {
      id: "img45",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-65",
      title: "Papel de parede Coreano Acácia",
      description: "32014-65",
   },
   {
      id: "img46",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-67",
      title: "Papel de parede Coreano Acácia",
      description: "32014-67",
   },
   {
      id: "img47",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-74",
      title: "Papel de parede Coreano Acácia",
      description: "32014-74",
   },
   {
      id: "img48",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-75",
      title: "Papel de parede Coreano Acácia",
      description: "32014-75",
   },
   {
      id: "img49",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-78",
      title: "Papel de parede Coreano Acácia",
      description: "32014-78",
   },
   {
      id: "img50",
      target: "",
      alt: "Papel de parede Coreano Acácia-32014-80",
      title: "Papel de parede Coreano Acácia",
      description: "32014-80",
   },
]

export { cards }